<template>
  <div class="NotFound py-20">
    <div class="constrain">
      <div class="row-12">
        <div class="col-12 mt-16 text-center">
          <h1>
            Diese Seite gibt es nicht.
          </h1>
          <Go :to="'/' + $store.state.router.locale">
            Zurück zur Startseite
          </Go>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
};
</script>

<style scoped lang="scss">
</style>
